import React from "react"
import { Link } from "gatsby"

class Breadcrumbs extends React.Component {
  render() {
    const items = this.props.items
    const absolute = this.props.absolute
    const contrast = this.props.contrast

    let breadcrumbClass = absolute
      ? "uk-section-xsmall uk-position-absolute uk-width-1-1"
      : "uk-section-xsmall uk-section-secondary"

    breadcrumbClass = breadcrumbClass + (contrast ? " uk-light" : "")

    const breadcrumbs = (
      <div className={breadcrumbClass}>
        <div className="uk-container uk-flex uk-flex-middle">
          <ol className="uk-breadcrumb">
            <li>
              <Link to="/" className="logo-link" activeClassName="uk-active">
                Home
              </Link>
            </li>
            {items.map((item) => (
              <li key={item.label}>
                {item.active ? (
                  <span>
                    {item.label}
                    {item.active}
                  </span>
                ) : (
                  <Link to={item.path} className="" activeClassName="uk-active">
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    )

    return breadcrumbs
  }
}

export default Breadcrumbs
